<template>
  <EntityList
    :config="config"
    :columns="columns"
    :filter-vals.sync="filterVals"
    :filters="filters"
    :form-fields="formFields"
    authority="General_Hospital"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from './storeModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      hospitalTypeOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'hospitals',
        endpoint: 'hospitals',
        route: 'hospitals',
        title: {
          single: this.$i18n.t('Hospital'),
          plural: this.$i18n.t('Hospitals'),
        },
      }
    },
    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'type.title', label: this.$t('Type'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    filters() {
      return [
        {
          name: 'type',
          label: this.$t('Type'),
          options: this.hospitalTypeOptions,
          value: null,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '2',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '1',
          maxDate: new Date(),
        },
      ]
    },
    formFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
        },
        {
          id: 'type',
          object: true,
          type: 'select',
          label: this.$t('Type'),
          options: this.hospitalTypeOptions,
          required: true,
        },
        {
          id: 'code',
          type: 'text',
          label: this.$t('code'),
        },
        {
          id: 'description',
          type: 'textarea',
          label: this.$t('description'),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchHospitalTypes').then(response => {
      this.hospitalTypeOptions = response.data
    })
  },
}
</script>
